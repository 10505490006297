import React, { useState, useEffect, useRef, useCallback } from 'react';

const GameLobby = () => {
  const [lobbyData, setLobbyData] = useState({
    playerName: 'Player1',
    coins: 1000,
    selectedGame: null
  });

  const [gameUrl, setGameUrl] = useState("");
  const iframeRef = useRef(null);
  const [loginSessionInput, setLoginSessionInput] = useState('');
  const [cocosData, setCocosData] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const handleonMounted = useCallback(() => {
    console.log("onMounted method called from Cocos");
    setIsReady(true);
    alert('onMounted called');
  }, []);

  useEffect(() => {
    const checkCocosData = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        const iframeWin = iframeRef.current.contentWindow;
        if (iframeWin.$wujie && iframeWin.$wujie.props && iframeWin.$wujie.props.data && 
            iframeWin.$wujie.props.data.data92 && iframeWin.$wujie.props.data.data92.setRoom) {
          const newCocosData = iframeWin.$wujie.props.data.data92.setRoom;
          setCocosData(newCocosData);
        }
      }
    };

    const intervalId = setInterval(checkCocosData, 1000); // check each second. for test

    return () => clearInterval(intervalId); // clean interval
  }, []);

  useEffect(() => {
    if (!window.$wujie) {
      window.$wujie = { props: { data: { data92: { loginSession: {} } } } };
      window.$wujie.props.event = window.$wujie.props.event || {};
    }
    
    // safty set onMounted event
    if (window.$wujie && window.$wujie.props && window.$wujie.props.event) {
        window.$wujie.props.event.onMounted = handleonMounted;
    }


    // the URL where cocos gameview build file host. //http://192.168.1.102:3000/  localhost:3000 https://test.gemini-pro.org/
    setGameUrl("http://nossl.gemini-pro.org/web-mobile11/index.html");
    return () => {
        if (window.$wujie?.props?.event) {
          delete window.$wujie.props.event.onMounted;
        }
      };
  }, [handleonMounted]);

  useEffect(() => {
    const updateIframeData = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        const iframeWin = iframeRef.current.contentWindow;

        
        
        if (!iframeWin.$wujie) {
          iframeWin.$wujie = { props: { data: {} } };
        }

        try {
          const parsedLoginSession = JSON.parse(loginSessionInput);
          iframeWin.$wujie.props.data = {
            ...lobbyData,
            data92: {
              loginSession: parsedLoginSession
            }
          };

          // updata the method
          iframeWin.$wujie.props.event = {
            onMounted: handleonMounted
          };

        } catch (error) {
          console.error('Invalid JSON input for loginSession:', error);
        }

        console.log('Updated iframe $wujie.props.data:', iframeWin.$wujie.props.data);
      }
    };

    updateIframeData();
  }, [lobbyData, loginSessionInput, handleonMounted]);

  const startGame = (gameName) => {
    console.log(`Starting game: ${gameName}`);
    setLobbyData(prevData => ({ ...prevData, selectedGame: gameName }));
  };

  const handleLoginSessionChange = (e) => {
    setLoginSessionInput(e.target.value);
  };

  return (
    <div className="game-lobby">
      <h1>React Game Lobby Test</h1>
      <p>Player: {lobbyData.playerName}</p>
      <p>coins: {lobbyData.coins}</p>
      <p>Ready status: {isReady ? 'Ready' : 'Not Yet'}</p>

      <div>
        <button onClick={() => startGame('game1')}>Enter Game1</button>
        <button onClick={() => startGame('game2')}>Enter Game2</button>
      </div>

      <button onClick={() => setLobbyData(prev => ({ ...prev, coins: prev.coins + 100 }))}>
        add coin
      </button>

      <div>
        <h2>Input LoginSession JSON</h2>
        <textarea
          value={loginSessionInput}
          onChange={handleLoginSessionChange}
          rows="10"
          cols="50"
        />
      </div>

      <div>
        <h2>Data from Cocos </h2>
        <pre>{JSON.stringify(cocosData, null, 2)}</pre>
      </div>

      {lobbyData.selectedGame && gameUrl && (
        <div style={{
            width: '100%',
            maxWidth: '540px', // 設置最大寬度為 540px（1080 的一半）
            aspectRatio: '16 / 9', // 設置寬高比為 9:16
            margin: '0 auto', // 居中顯示
          }}>
            <iframe
            ref={iframeRef}
            src={gameUrl}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            onLoad={() => console.log("iframe loaded")}
            />
        </div>
      )}
    </div>
  );
};

export default GameLobby;